import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

const Navigation = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  const isAuthenticated = route === "authenticated";

  return (
    <div className="nav-wrapper">
      <div className="d-flex">
        <span onClick={() => navigate("/")}>Home</span>
        <span onClick={() => navigate("/services")}>Services</span>
        <span onClick={() => navigate("/terms-and-conditions")}>
          Terms and conditions
        </span>
        {isAuthenticated && (
          <span onClick={() => navigate("/days-out")}>Days out</span>
        )}
        {/*{isAuthenticated && <span>Add product</span>}*/}
        {isAuthenticated && (
          <span onClick={() => navigate("/orders")}>Orders</span>
        )}
        {isAuthenticated && (
          <span
            onClick={() => {
              Auth.signOut();
              navigate("/services");
            }}
          >
            Sign out
          </span>
        )}
      </div>
      <div className="d-flex flex-row"></div>
    </div>
  );
};

export default Navigation;
