import { createMoment } from "shared/dateUtils";
import { Product } from "types/Product";

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const findFridaysAndSaturdays = (product: Product) => {
  // Set the current date as the starting point
  const currentDate = createMoment();

  // Create an array to store the dates
  const nextTwoMonthsFridaysSaturdays = [];

  // Loop through the next two months
  for (let i = 0; i < 3; i++) {
    const nextMonth = currentDate.clone().add(i, "months");

    // Find the first Friday of the month
    const firstFriday = nextMonth.clone().startOf("month").day("Friday");

    // Find the first Saturday of the month
    const firstSaturday = nextMonth.clone().startOf("month").day("Saturday");

    // Find the first Saturday of the month
    const firstSunday = nextMonth.clone().startOf("month").day("Sunday");

    // Loop through the Fridays and Saturdays in the month
    for (let j = 0; j < 5; j++) {
      nextTwoMonthsFridaysSaturdays.push(firstFriday.toDate());

      if (product.period === 15) {
        nextTwoMonthsFridaysSaturdays.push(firstSaturday.toDate());
      }

      nextTwoMonthsFridaysSaturdays.push(firstSunday.toDate());

      firstFriday.add(7, "days");
      firstSaturday.add(7, "days");
      firstSunday.add(7, "days");
    }
  }

  return nextTwoMonthsFridaysSaturdays;
};

export const isSaturday = (pickedDate: Date) => {
  // Parse the picked date using Moment.js
  const date = createMoment(pickedDate);

  // Check if the day of the week is Saturday (6)
  return date.day() === 6;
};

export const handleStartOfMeeting = (
  date: Date | undefined,
  product: Product,
) => {
  if (!date) return "";
  if (product.period === 15) return "17:30";
  if (isSaturday(date)) return "11:00";
  return "17:00";
};
