import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Authenticator } from "@aws-amplify/ui-react";
import { store } from "store";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure(awsExports);

ReactDOM.render(
  <Authenticator.Provider>
    <BrowserRouter>
      <ToastContainer autoClose={3000} theme="colored" />
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </Authenticator.Provider>,
  document.getElementById("root"),
);
