import Home from "Home";
import Orders from "Orders/Orders";
import Login from "Login";
import RequiredAuth from "shared/RequiredAuth/RequiredAuth";
import Terms from "Terms";
import Products from "Products/Products";
import DaysOut from "DaysOut";
import Success from "PaymentFinished/Success";
import Cancel from "PaymentFinished/Cancel";
export const router = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/services",
    exact: true,
    element: <Products />,
  },
  {
    path: "/orders",
    exact: true,
    element: (
      <RequiredAuth>
        <Orders />
      </RequiredAuth>
    ),
  },
  {
    path: "/days-out",
    exact: true,
    element: (
      <RequiredAuth>
        <DaysOut />
      </RequiredAuth>
    ),
  },
  {
    path: "/login",
    exact: true,
    element: <Login />,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    element: <Terms />,
  },
  {
    path: "/success",
    exact: true,
    element: <Success />,
  },
  {
    path: "/cancel",
    exact: true,
    element: <Cancel />,
  },
];
