import "./styles.css";
import { useNavigate } from "react-router";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="wrapper">
      <h1 className="success-title">Payment successful!</h1>
      <p>Thank you for your purchase.</p>
      <p>You will get the meeting details shortly!</p>
      <button onClick={() => navigate("/services")}>Back to services</button>
    </div>
  );
};

export default Success;
