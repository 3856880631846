import api from "utils/api";

export const createPayment = (payload: {
  productId: string;
  requestedDateTime: string;
  userInfo: {
    email: string;
    fullName: string;
  };
}) => api.post<{ sessionUrl: string }>("create-payment-intent", payload);
