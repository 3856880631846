import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import { Product } from "types/Product";
import DateRangeIcon from "shared/icons/DateRangeIcon";
import { ICON_SIZE_XL } from "shared/constants";
import LocationIcon from "shared/icons/LocationIcon";
import { getDaysOut } from "services/DaysOut";
import {
  createMoment,
  DATABASE_DATE_FORMAT,
  DATABASE_DATE_TIME_FORMAT,
  DISPLAY_TIME_FORMAT,
} from "shared/dateUtils";
import Spinner from "Spinner/Spinner";
import KeyboardBackspaceIcon from "shared/icons/KeyboardBackspaceIcon";
import {
  findFridaysAndSaturdays,
  handleStartOfMeeting,
  isSaturday,
} from "shared/utils";

type Props = {
  product: Product;
  onSelectDateTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
  onBackToProducts: () => void;
};
const DatePicker = ({ product, onSelectDateTime, onBackToProducts }: Props) => {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [daysOut, setDaysOut] = useState<Date[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const disabledFridaysAndSaturdays = findFridaysAndSaturdays(product);

  useEffect(() => {
    setLoading(true);
    getDaysOut()
      .then((response) => {
        const daysOutResponse: Date[] = [];

        Object.keys(response.data).forEach((day) => {
          if (response.data[day].length === 2) {
            daysOutResponse.push(
              createMoment(day, DATABASE_DATE_FORMAT).toDate(),
            );
          }

          if (response.data[day].length === 1) {
            if (isSaturday(createMoment(day, DATABASE_DATE_FORMAT).toDate())) {
              daysOutResponse.push(
                createMoment(day, DATABASE_DATE_TIME_FORMAT).toDate(),
              );
            }

            // if (product.period === 15 && response.data[day][0] === "17:30") {
            //   daysOutResponse.push(
            //     createMoment(day, DATABASE_DATE_TIME_FORMAT).toDate(),
            //   );
            // }

            if (product.period !== 15 && response.data[day][0] === "17:00") {
              daysOutResponse.push(
                createMoment(day, DATABASE_DATE_TIME_FORMAT).toDate(),
              );
            }
          }
        });
        setDaysOut(daysOutResponse);
      })
      .finally(() => setLoading(false));
  }, []);

  const fromDate = moment().add(2, "days").toDate();
  const toDate = moment().add(2, "months").toDate();

  const startOfMeeting = handleStartOfMeeting(selectedDay, product);

  const handleNext = () => {
    // Create a moment object for the initial time
    const initialTime = moment(selectedDay);

    // Parse "17:30" and get its hours and minutes
    const additionalTime = moment(selectedTime, DISPLAY_TIME_FORMAT);

    // Extract the hours and minutes
    const hours = additionalTime.hours();
    const minutes = additionalTime.minutes();

    // Add hours and minutes to the initial time
    const result = initialTime
      .add(hours, "hours")
      .add(minutes, "minutes")
      .toDate();
    onSelectDateTime(result);
  };

  if (loading)
    return (
      <div className="d-flex m-auto">
        <Spinner />
      </div>
    );

  return (
    <div className="day-picker-main-wrapper">
      <div
        className="back-to-services"
        onClick={onBackToProducts}
        role="button"
      >
        <span>
          <KeyboardBackspaceIcon size={ICON_SIZE_XL} />
        </span>
        <span>Back to services</span>
      </div>
      <div className="day-picker-wrapper">
        <div className="text-section">
          <h2>
            <strong>{product.title}</strong>
          </h2>
          <div className="d-flex flex-row align-items-center">
            <span>
              <DateRangeIcon size={ICON_SIZE_XL} />
            </span>
            <span className="m-1">
              <strong>{product.period} mins</strong>
            </span>
          </div>
          <div className="d-flex flex-row align-items-center">
            <span>
              <LocationIcon size={ICON_SIZE_XL} />
            </span>
            <span className="m-1">
              <strong>Google meet upon confirmation</strong>
            </span>
          </div>
          <span className="description">{product.description}</span>
        </div>
        <DayPicker
          selected={selectedDay}
          disabled={[...(daysOut || []), ...disabledFridaysAndSaturdays]}
          fromDate={fromDate}
          toDate={toDate}
          ISOWeek
          showOutsideDays
          mode="single"
          onSelect={(day) => {
            setSelectedDay(day);
            setSelectedTime(null);
          }}
        />
        {selectedDay && (
          <div className="button-wrapper">
            <span>{moment(selectedDay).format("dddd, MMMM D")}</span>
            <div className="d-flex w-100">
              <button
                onClick={() => setSelectedTime(startOfMeeting)}
                className="day-picker-button"
              >
                {startOfMeeting}
              </button>
              {selectedTime && (
                <button onClick={handleNext} className="day-picker-button-next">
                  Next
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
