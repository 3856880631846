import { useEffect, useState } from "react";
import { getOrders } from "services/Orders";
import { Order } from "types/Orders";
import DownloadIcon from "shared/icons/DownloadIcon";
import { ICON_SIZE_L } from "shared/constants";
import "Orders/styles.css";
import Spinner from "Spinner/Spinner";
import {
  createMoment,
  DATABASE_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  formatDateToDisplayDateTimeFormat,
} from "shared/dateUtils";
const Orders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getOrders()
      .then((res) => setOrders(res.data))
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <div className="d-flex m-auto">
        <Spinner />
      </div>
    );

  return (
    <div className="w-100">
      <table className="container">
        <thead className="head">
          <tr>
            <th>Paid amount</th>
            <th>Invoice number</th>
            <th>Meeting date</th>
            <th>Status</th>
            <th>Paid at</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr className="py-2" key={order._id}>
              <td style={{ fontWeight: "bold" }}>{order.total} €</td>

              <td>{order.invoiceNumber}</td>

              <td>{formatDateToDisplayDateTimeFormat(order.meetingDate)}</td>

              <td>
                <span
                  className={
                    order.status === "paid" ? "paid-cell" : "incomplete-cell"
                  }
                >
                  {order.status}
                </span>
              </td>

              <td>
                {createMoment(order.paidAt, DATABASE_DATE_FORMAT).format(
                  DISPLAY_DATE_FORMAT,
                )}
              </td>

              <td
                role="button"
                style={{ width: "2rem", height: "2rem" }}
                onClick={() => window.open(order.invoiceUrl, "")}
              >
                <span>
                  <DownloadIcon size={ICON_SIZE_L} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
