import { ProgressBar } from "react-loader-spinner";

const ProgressBarSpinner = () => {
  return (
    <ProgressBar
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{
        height: "100px",
        width: "100px",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
      }}
      wrapperClass="progress-bar-wrapper"
      borderColor="#003040"
      barColor="#51E5FF"
    />
  );
};

export default ProgressBarSpinner;
