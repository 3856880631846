import axios from "axios";
import { URL } from "shared/constants";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

// Create an Axios instance with default options
const axiosInstance = axios.create({
  baseURL: URL, // Replace with your backend API URL
  withCredentials: true, // Send cookies with requests
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast.error(error.response.data.message, {
      type: "error",
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    if (error.response.status === 401) {
      // If we get a 401 response, and it's not because of a failed refresh,
      // logout the user
      if (!error.response.data.message.includes("refresh")) {
        // Auth.signOut();
      }
    }
    return Promise.reject(error);
  },
);

// Add an interceptor to update the "Authorization" header before each request
axiosInstance.interceptors.request.use(async (config) => {
  try {
    // Define an array of endpoint URLs that should receive the token
    const authorizedEndpoints = ["orders", "days-out"]; // Add your specific endpoints here

    // Check if the request URL matches any of the authorized endpoints
    if (
      authorizedEndpoints.some(
        (endpoint) => config.url && config.url === endpoint,
      )
    ) {
      const user = await Auth.currentAuthenticatedUser();

      const idToken = user.signInUserSession.idToken.jwtToken;
      // If so, attach the token to the Authorization header
      config.headers.Authorization = `Bearer ${idToken}`;
    }
  } catch (error) {
    // Handle error if authentication session retrieval fails
    console.error("Error retrieving authentication session:", error);
  }
  return config;
});

export default axiosInstance;
