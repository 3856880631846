import api from "utils/api";

export type GetDaysOutResponse = Record<string, string[]>;

export type PostDaysOutRequest = {
  daysOut: Date[];
};

export const getDaysOut = () => api.get<GetDaysOutResponse>("unavailabilities");

export const postDaysOut = (data: PostDaysOutRequest) =>
  api.post("days-out", data);

export const deleteDaysOut = (id: string) => api.delete(`days-out/${id}`);
