import { DayPicker } from "react-day-picker";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import DateRangeIcon from "shared/icons/DateRangeIcon";
import { ICON_SIZE_XL } from "shared/constants";
import { getDaysOut, postDaysOut } from "services/DaysOut";
import { createMoment, DATABASE_DATE_FORMAT } from "shared/dateUtils";
import Spinner from "Spinner/Spinner";
import { toast } from "react-toastify";

const DaysOut = () => {
  const [selectedDays, setSelectedDays] = useState<Date[] | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fromDate = moment().add(2, "days").toDate();
  const toDate = moment().add(2, "months").toDate();

  useEffect(() => {
    setLoading(true);
    getDaysOut()
      .then((response) => {
        const daysOutResponse: Date[] = [];

        Object.keys(response.data).forEach((day) => {
          if (response.data[day].length === 1) {
            daysOutResponse.push(
              createMoment(day, DATABASE_DATE_FORMAT).toDate(),
            );
          }
        });
        setSelectedDays(daysOutResponse);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSave = async () => {
    if (!selectedDays?.length) return;
    setProcessing(true);

    const duplicates = [...selectedDays];

    // Loop through each date in the array and set the time to 18:00
    duplicates.forEach((date) => {
      date.setHours(17); // Set the hours to 18
      date.setMinutes(0); // Set the minutes to 0
      date.setSeconds(0); // Set the seconds to 0 (optional)
      date.setMilliseconds(0); // Set the milliseconds to 0 (optional)
    });

    try {
      await postDaysOut({
        daysOut: duplicates,
      });
      toast.success("Days out saved successfully!");
    } finally {
      setProcessing(false);
    }
  };

  if (loading)
    return (
      <div className="d-flex m-auto">
        <Spinner />
      </div>
    );

  return (
    <>
      <div className="day-picker-wrapper">
        <div>
          <h4>
            <strong>Days out</strong>
          </h4>
          <div className="days-out-section">
            {selectedDays?.map((selected) => (
              <div className="d-flex flex-row align-items-center">
                <span>
                  <DateRangeIcon size={ICON_SIZE_XL} />
                </span>
                <span className="m-1">
                  <strong>{moment(selected).format("DD.MM.YYYY")}</strong>
                </span>
              </div>
            ))}
          </div>
          <div className="button-wrapper">
            <button
              disabled={!selectedDays?.length || processing}
              onClick={handleSave}
              className="day-picker-button-next"
            >
              {processing ? "Processing..." : "Save"}
            </button>
          </div>
        </div>
        <DayPicker
          selected={selectedDays}
          fromDate={fromDate}
          toDate={toDate}
          ISOWeek
          showOutsideDays
          mode="multiple"
          onSelect={setSelectedDays}
        />
      </div>
    </>
  );
};

export default DaysOut;
