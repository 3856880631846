import { useEffect, useState } from "react";
import { getProducts } from "services/products";
import { Product as ProductType } from "types/Product";
import "./styles.css";

import ProgressBarSpinner from "Spinner/Spinner";
import Product from "Products/Product";
import DatePicker from "DatePicker/DatePicker";
import UserInfo from "UserInfo";
import { DATABASE_DATE_TIME_FORMAT } from "shared/dateUtils";
import moment from "moment";
const Products = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(
    null,
  );
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>();

  const fetchProducts = async () => {
    setLoading(true);
    const response = (await getProducts()).data;
    setLoading(false);
    setProducts(response);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) return <ProgressBarSpinner />;

  if (selectedProduct && !selectedDateTime && selectedProduct.period) {
    return (
      <DatePicker
        onSelectDateTime={setSelectedDateTime}
        product={selectedProduct}
        onBackToProducts={() => setSelectedProduct(null)}
      />
    );
  }

  if (selectedProduct) {
    return (
      <UserInfo
        product={selectedProduct}
        requestedDateTime={moment(selectedDateTime).format(
          DATABASE_DATE_TIME_FORMAT,
        )}
        onBackToProducts={() => {
          setSelectedDateTime(undefined);
          setSelectedProduct(null);
        }}
        onBackToDatePicker={() => setSelectedDateTime(undefined)}
      />
    );
  }

  return (
    <div className="d-flex flex-column overflow-auto ">
      <div className="products-intro">
        <h1>Services</h1>
        <span>
          I offer different services to help you succeed in the Finnish job
          market. Choose the one that suits you and your needs best!
        </span>
        <span>
          Get in touch if you would like to discuss a more individual approach.
        </span>
      </div>
      <div className="d-flex flex-row flex-wrap justify-content-center">
        {products.map((item) => (
          <Product
            key={item.id}
            item={item}
            onSelectProduct={() => setSelectedProduct(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
