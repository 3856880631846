import api from "utils/api";
import { AddToCartPayload, CartItem } from "types/Cart";

export const addToCart = (payload: AddToCartPayload) =>
  api.post<CartItem>("cart", payload);
export const getCart = () => api.get<CartItem[]>("cart");

export const deleteCartItem = (productId: string) =>
  api.post<CartItem[]>("delete-cart-item", { productId });

export const emptyCart = () => api.post("empty-cart");
