import { useCallback, useState } from "react";
import { createPayment } from "services/Checkout";
import { Product } from "types/Product";
import { isValidEmail } from "shared/utils";
import "./styles.css";
import KeyboardBackspaceIcon from "shared/icons/KeyboardBackspaceIcon";
import { ICON_SIZE_XL } from "shared/constants";

type Props = {
  product: Product;
  requestedDateTime: string;
  onBackToDatePicker: () => void;
  onBackToProducts: () => void;
};
const UserInfo = ({
  product,
  requestedDateTime,
  onBackToProducts,
  onBackToDatePicker,
}: Props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [fullName, setFullName] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);

  const handleCreatePaymentIntent = useCallback(async () => {
    try {
      if (!isValidEmail(email)) {
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }
      if (!fullName || !email) return;

      setIsProcessing(true);
      const response = await createPayment({
        productId: product.id,
        userInfo: { email, fullName },
        requestedDateTime,
      });

      window.open(response.data.sessionUrl, "_self");
    } catch (error) {
      // do nothing
    } finally {
      setIsProcessing(false);
    }
  }, [email, fullName, product.id, requestedDateTime]);

  return (
    <div className="userInfo-wrapper">
      <div className="d-flex flex-row mb-5">
        <div
          className="back-to-services"
          onClick={onBackToProducts}
          role="button"
        >
          <span>
            <KeyboardBackspaceIcon size={ICON_SIZE_XL} />
          </span>
          <span>Back to services</span>
        </div>
        {product.period && (
          <div
            onClick={onBackToDatePicker}
            role="button"
            className="back-to-services"
          >
            <span>
              <KeyboardBackspaceIcon />
            </span>
            <span>Back to dates</span>
          </div>
        )}
      </div>
      <span className="contact-info">Contact information</span>
      <div className="userInfo-email">
        <span>Email</span>
        <input
          type="email"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
        />
        {emailError && (
          <span className="error-text">Please check your email</span>
        )}
      </div>

      <div className="userInfo-fullName">
        <span>Full name</span>
        <input
          onChange={(event) => setFullName(event.target.value)}
          value={fullName}
          type="text"
        />
      </div>

      <button
        disabled={!email || !fullName || isProcessing}
        onClick={handleCreatePaymentIntent}
      >
        {isProcessing ? "Processing..." : "Continue"}
      </button>
    </div>
  );
};

export default UserInfo;
