import React from "react";
import { Route, Routes } from "react-router-dom";
import Navigation from "shared/Navigation/Navigation";
import "@aws-amplify/ui-react/styles.css";
import { router } from "shared/routes";
import "./index.css";
function MyRoutes() {
  return (
    <Routes>
      {router.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

const App = () => {
  return (
    <div className="app-root">
      <div className="app-main">
        <Navigation />
        <MyRoutes />
      </div>
    </div>
  );
};

export default App;
