import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem } from "types/Cart";
import {
  addToCart as addToCartApi,
  deleteCartItem,
  getCart,
} from "services/Carts";

export type CartState = {
  items: CartItem[];
  cartDetailModal: boolean;
};

const initialState: CartState = {
  items: [],
  cartDetailModal: false,
};

export const addToCartThunk = createAsyncThunk(
  "app-cart-addToCart",
  async (
    { productId, quantity }: { productId: string; quantity: number },
    { dispatch },
  ) => {
    const result = (
      await addToCartApi({
        productId,
        quantity,
      })
    ).data;
    dispatch(setCart(result));
  },
);

export const loadCart = createAsyncThunk(
  "load-cart",
  async () => (await getCart()).data,
);

export const deleteItemFromCart = createAsyncThunk(
  "delete-item-from-cart",
  async ({ productId }: { productId: string }) =>
    (await deleteCartItem(productId)).data,
);

export const counterSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (draft, action: PayloadAction<CartItem>) => {
      if (draft.items.length === 0) {
        draft.items.push(action.payload);
      } else {
        const index = draft.items.findIndex(
          (item) => item.id === action.payload.id,
        );
        if (index === -1) {
          draft.items.push(action.payload);
        } else {
          draft.items[index].quantity += action.payload.quantity;
        }
      }
    },
    setCart: (draft, action: PayloadAction<CartItem>) => {
      const itemIndex = draft.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (itemIndex === -1) draft.items.push(action.payload);
      else {
        draft.items[itemIndex] = action.payload;
      }
    },
    deleteItem: (draft, action: PayloadAction<string>) => {
      const itemIndex = draft.items.findIndex(
        (item) => item.product.id === action.payload,
      );
      if (itemIndex !== -1) draft.items.splice(itemIndex, 1);
    },
    showCartDetailModal: (draft, action: PayloadAction<boolean>) => {
      draft.cartDetailModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadCart.fulfilled,
      (state, action: PayloadAction<CartItem[]>) => {
        state.items = action.payload;
      },
    );
    builder.addCase(
      deleteItemFromCart.fulfilled,
      (state, action: PayloadAction<CartItem[]>) => {
        state.items = action.payload;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const { setCart, addToCart, deleteItem, showCartDetailModal } =
  counterSlice.actions;

export default counterSlice.reducer;
