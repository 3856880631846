import "./styles.css";
import { useNavigate } from "react-router";

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div className="wrapper">
      <h1 className="cancel-title">Something went wrong!</h1>
      <p>Please try again</p>
      <p>Or contact the administrator</p>
      <div className="d-flex flex-row">
        <p>at</p>
        <p
          className="email"
          onClick={() => {
            navigate("mailto:coaching@jobboostbyrebekka.com");
          }}
        >
          coaching@jobboostbyrebekka.com
        </p>
      </div>

      <button onClick={() => navigate("/services")}>Back to services</button>
    </div>
  );
};

export default Cancel;
