import "./styles.css";
// @ts-ignore
import rebekka from "Images/img.png";
// @ts-ignore
import nature from "Images/nature.png";
// @ts-ignore
import conversation from "Images/conversation.png";
// @ts-ignore
import sec1 from "Images/sec1.png";
// @ts-ignore
import sec2 from "Images/sec2.png";
// @ts-ignore
import sec3 from "Images/sec3.png";
// @ts-ignore
import sec4 from "Images/sec4.png";
// @ts-ignore
import sec5 from "Images/sec5.png";
// @ts-ignore
import sec6 from "Images/sec6.png";
// @ts-ignore
import contact from "Images/contact.png";
import LinkedInIcon from "shared/icons/LinkedIn";
import { ICON_SIZE_XL } from "shared/constants";
import { useNavigate } from "react-router";

const Home = () => {
  const navigate = useNavigate();

  const handleBookTime = () => {
    navigate("/services");
  };

  return (
    <div className="home-wrapper">
      <div className="wrapper-first-section">
        <h1>Jobboost: Accelerate Your Career with</h1>
        <h1>Coaching in Finland</h1>
        <span>Unlock Your Full Potential ✨</span>
      </div>

      <div className="wrapper-second-section">
        <div>
          <h1>Hi, I am Rebekka, your job booster 👋🏻</h1>
          <img className="small-screen-img" alt="rebekka" src={rebekka} />
          <p>
            I have been recruiting in Finland and Germany for the past 6 years
            and make decisions about CVs and interviews every day.
          </p>
          <p>
            Now I want to share my knowledge with you and help you leverage your
            skills and expertise in the right way.
          </p>
          <p>
            Your dream job is out there, you just need to find it and grab it!
          </p>
          <button onClick={handleBookTime}>Book your time here</button>
        </div>
        <img className="big-screen-img" alt="rebekka" src={rebekka} />
      </div>

      <div className="wrapper-third-section">
        <img className="big-screen-img" src={nature} alt="nature" />
        <div>
          <h1>Boost Your Career with Job Boost</h1>
          <img className="small-screen-img" src={nature} alt="nature" />
          <p>Unlock your full potential and achieve your career goals!</p>
          <p>
            My coaching is specifically tailored to your situation, needs, and
            goals. Because everyone is unique ✨
          </p>
          <p>
            Together we will assess what you need and explore strategies and
            techniques to take your job search to tne next level.
          </p>
          <button onClick={handleBookTime}>Book your time here</button>
        </div>
      </div>

      <div className="wrapper-second-section">
        <div>
          <h1>Take the next steps in your career with JobBoost</h1>
          <img
            className="small-screen-img"
            src={conversation}
            alt="conversation"
          />
          <p>
            Are you unhappy in your current job? Or maybe looking for new
            challenges?
          </p>
          <p>
            Let's take the next steps together and make sure that you find a new
            career that makes you happy.
          </p>
          <button onClick={handleBookTime}>Book your time here</button>
        </div>
        <img className="big-screen-img" src={conversation} alt="conversation" />
      </div>

      <div className="wrapper-fifth-section">
        <h1>Coaching that is tailored to your individual needs</h1>
        <div className="row">
          <div className="col">
            <img src={sec1} alt="sec1" />
            <p>
              Free <strong>15-minute consultation</strong> to assess your needs.{" "}
            </p>
          </div>
          <div className="col">
            <img src={sec2} alt="sec2" />
            <p>
              <strong>Resume advice</strong> to make your CV stand out from the
              crowd.
            </p>
          </div>
          <div className="col">
            <img src={sec3} alt="sec3" />
            <p>
              <strong>Interview training</strong> to help you get that job
              offer.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <img src={sec4} alt="sec4" />
            <p>
              <strong>Skill assessment</strong> to define what jobs you will be
              happy and successful in.
            </p>
          </div>
          <div className="col">
            <img src={sec5} alt="sec5" />
            <p>
              <strong>Insights</strong> into the Finnish job market and your
              industry.
            </p>
          </div>
          <div className="col">
            <img src={sec6} alt="sec6" />
            <p>
              A true <strong>boost of motivation</strong> to your job search.
            </p>
          </div>
        </div>
        <button onClick={handleBookTime}>Book your time here</button>
      </div>

      <div className="wrapper-seventh-section">
        <div>
          <h1>Connect with me on LinkedIn and Twitter</h1>
          <p>
            I post free and actionable job search tips on my LinkedIn profile
            every Monday and Thursday.
          </p>
          <p>I also post short, sweet bits on Twitter.</p>
          <p>Let's connect and be in touch!</p>
          <div>
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/rebekkagotter",
                  "_blank",
                )
              }
              role="button"
            >
              <LinkedInIcon size={ICON_SIZE_XL} />
            </span>
          </div>
        </div>
        <img alt="contact" src={contact} />
      </div>
    </div>
  );
};

export default Home;
