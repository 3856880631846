import { useEffect, useState } from "react";
import { Product as ProductType } from "types/Product";
import "./styles.css";
// @ts-ignore
import preload from "Images/preload.png";

type Props = { item: ProductType; onSelectProduct: () => void };
const Product = ({ item, onSelectProduct }: Props) => {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    import(`Images/${item.title.toLowerCase().replace(/\s/g, "")}.png`).then(
      (image) => {
        setImageUrl(image.default);
      },
    );
  }, [item.title]);

  return (
    <div className="card">
      <div className="d-flex flex-column">
        <img loading="lazy" src={imageUrl || preload} alt={item.title} />

        <h1>{item.title}</h1>

        <span className="my-2">{item.description}</span>

        {item.subDescription && (
          <span className="my-2">{item.subDescription}</span>
        )}

        {item.points && (
          <ul className="my-4">
            {item.points.map((point) => (
              <li>{point}</li>
            ))}
          </ul>
        )}

        {item.footer && <span className="my-2">{item.footer}</span>}
      </div>

      {!!item.price && (
        <span className="product-price">Price: {item.price}€</span>
      )}

      <button onClick={onSelectProduct} className="add-to-cart-button">
        Book your time
      </button>
    </div>
  );
};

export default Product;
