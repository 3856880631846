import React from "react";
import { ICON_SIZE } from "shared/icons/constants";
import { IconProps } from "shared/icons/IconProps";

const KeyboardBackspaceIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 -960 960 960"
    width={size}
  >
    <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
  </svg>
);

export default KeyboardBackspaceIcon;
