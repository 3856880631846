import moment from "moment";

export const DATABASE_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DATABASE_DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const DISPLAY_DATE_FORMAT = "DD.MM.YYYY";
export const DISPLAY_TIME_FORMAT = "HH:mm";
export const getCurrentTime = (): string =>
  moment().format(DISPLAY_TIME_FORMAT);

export const getCurrentDate = (): string =>
  moment().format(DISPLAY_DATE_FORMAT);
export const getCurrentDateTime = (): string =>
  moment().format(DISPLAY_DATE_TIME_FORMAT);

export const formatDateToDisplayDateTimeFormat = (date: string): string =>
  moment(date).format(DISPLAY_DATE_TIME_FORMAT);

export const createMoment = (
  date?: string | Date,
  format?: string,
): moment.Moment => moment(date, format);
